// AuthTokenProvider.js
import React, { useEffect } from 'react';
import { useAuthInfo } from '@propelauth/react';
import { setAuthorizationHeader } from '../../services/api';

const AuthTokenProvider = () => {
  const { accessToken } = useAuthInfo();

  useEffect(() => {
    if (accessToken) {
      setAuthorizationHeader(accessToken); // Set the access token in Axios
    }
  }, [accessToken]);

  return null; // This component doesn't need to render anything
};

export default AuthTokenProvider;