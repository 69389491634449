import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - browser
const BrowserPage = Loadable(lazy(() => import('pages/browser/BrowserPage')));

// render - runs
const Runs = Loadable(lazy(() => import('pages/runs/index')));

// render - apikeys
const APIKeys = Loadable(lazy(() => import('pages/api_keys/index')));

// render - workflows
const Workflows = Loadable(lazy(() => import('pages/workflows/index')));

// render - workflow details page
const Flow = Loadable(lazy(() => import('pages/workflows/WorkflowPage')));
// render - workflow details page
const WorkflowPageBrowser = Loadable(lazy(() => import('pages/workflows/WorkflowPageBrowser')));

const WorkflowEdit = Loadable(lazy(() => import('pages/workflows/WorkflowEdit')));

// render - workflow runs page
const WorkflowRuns = Loadable(lazy(() => import('pages/workflows/WorkflowRuns')));
const RunDetails = Loadable(lazy(() => import('pages/workflows/RunDetails')));

// render - userdetails
const UserDetailsPage = Loadable(lazy(() => import('pages/user/UserDetails')));

// render - sample page
const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <Workflows />
    },
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'sample-page',
      element: <SamplePage />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    },
    {
      path: 'browser',
      element: <BrowserPage />
    },
    {
      path: 'runs',
      element: <Runs />
    },
    {
      path: 'api_keys',
      element: <APIKeys />
    },
    {
      path: 'workflows',
      element: <Workflows />
    },
    {
      path: 'workflow/:id',
      element: <Flow />
    },
    {
      path: 'workflowbrowser/:id',
      element: <WorkflowPageBrowser />
    },
    {
      path: 'workflowbrowser/:workflow_uuid/runs',
      element: <WorkflowRuns />
    },
    {
      path: "/run-details/:runId",
      element: <RunDetails />
    },
    {
      path: 'workflowbrowser/:workflow_uuid/edit',
      element: <WorkflowEdit />
    },
    {
      path: 'user',
      element: <UserDetailsPage />
    }
  ]
};

export default MainRoutes;
