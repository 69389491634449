// assets
import { DashboardOutlined, KeyOutlined, FileTextOutlined, UserOutlined, LinkOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  KeyOutlined,
  FileTextOutlined,
  UserOutlined,
  LinkOutlined
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Home',
  type: 'group',
  children: [
    {
      id: 'workflows',
      title: 'Workflows',
      type: 'item',
      url: '/workflows',
      icon: icons.FileTextOutlined,
      breadcrumbs: false
    },
    {
      id: 'api_keys',
      title: 'API Keys',
      type: 'item',
      url: '/api_keys',
      icon: icons.KeyOutlined,
      breadcrumbs: false
    },
    {
      id: 'docs',
      title: 'API Documentation',
      type: 'item',
      url: 'https://basepilot-api.docs.buildwithfern.com',
      icon: icons.LinkOutlined,
      target: '_blank',
      breadcrumbs: false
    },
    {
      id: 'user',
      title: 'User Details',
      type: 'item',
      url: '/user',
      icon: icons.UserOutlined,
      breadcrumbs: false
    }
  ]
};

export default dashboard;
