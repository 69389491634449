// material-ui
import { Box, useMediaQuery } from '@mui/material';

// project import
//import Search from './Search';
import Profile from './Profile';
//import Notification from './Notification';
import MobileSection from './MobileSection';

// ==============================|| HEADER - CONTENT ||============================== //

const HeaderContent = () => {
  const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <>
      {/* Responsive conditional rendering */}
      {matchesXs ? (
        <>
          <Box sx={{ width: '100%' }}>
            {/* Mobile-specific content */}
            <MobileSection />
          </Box>
        </>
      ) : (
        <>
          {/* Flex container for right-aligned items */}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            {/* Render Notification and Profile when not in mobile view */}
            {/* removing notifications <Notification /> */}
            <Profile />
          </Box>
        </>
      )}
    </>
  );
};

export default HeaderContent;
